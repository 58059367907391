<template>
  <div>
    <headbox title="消息详情" bgColor="#fff" isborder="true"></headbox>
    <div class="aritce_details">
      <h1>{{ title }}</h1>
      <div class="time tr">{{ enableTime | formatDate_ }}</div>
      <div class="centerbox">{{ content }}</div>
    </div>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
import * as msglist from "@/utils/api";
import { formatDate_ } from "@/utils/filters";
export default {
  data() {
    return {
      content: "",
      enableTime: "",
      title: ""
    };
  },
  components: {
    headbox
  },
  filters: {
    formatDate_
  },
  mounted() {
    const $router_ = this.$route.query.id;
    msglist.msgdetails($router_).then(res => {
      this.content = res.content;
      this.enableTime = res.enableTime;
      this.title = res.title;
    });
  }
};
</script>
<style lang="scss" scoped="true">
.aritce_details {
  margin: 30px;
  h1 {
    color: #1a1a1a;
    font-size: 36px;
    text-align: center;
  }
  .time {
    color: #b3b3b3;
    font-size: 24px;
    margin: 30px 0;
  }
  .centerbox {
    color: #333333;
    font-size: 28px;
  }
}
</style>
