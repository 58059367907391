var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("headbox", {
        attrs: { title: "消息详情", bgColor: "#fff", isborder: "true" }
      }),
      _c("div", { staticClass: "aritce_details" }, [
        _c("h1", [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "time tr" }, [
          _vm._v(_vm._s(_vm._f("formatDate_")(_vm.enableTime)))
        ]),
        _c("div", { staticClass: "centerbox" }, [_vm._v(_vm._s(_vm.content))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }